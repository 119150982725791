(function(){
     'use strict';

     angular
        .module('app')
        .factory('ProjectService', ProjectService);

    ProjectService.$inject = ['$http'];

    function ProjectService($http,$q,$window,$location){

        var project= {
           getProjects:getProjects,
           getHome:getHome
        };

 		function getProjects(offset,limit)
        {
            var config = {headers : {}}

            return $http.get('/ajax/projects', {  
            params : { "offset":offset,"limit":limit,"type":'projects', lang_id:lang_id }  
            
            }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };

        function getHome()
        {
            var config = {headers : {}}

            return $http.get('/ajax/home', {  
            
            
            }, config)
            .then(function(data){
                return data.data;
            },function(data){
                return data;
            });
        };

        
        return project;
    }

})();