   gsap.registerPlugin(ScrollTrigger);

   // fadein animation 

   const fadeIn = gsap.utils.toArray('.o-fadeIn');

   fadeIn.forEach((module, i) => {
      ScrollTrigger.create({
         trigger: module,
         start: "top+=100px bottom",
         toggleClass: "o-fadeIn__animated",
         once: true,
         // markers: true
   
      })
   });

   // fadeinup animation 

   const fadeInUp = gsap.utils.toArray('.o-fadeInUp');

   fadeInUp.forEach((module, i) => {

      ScrollTrigger.matchMedia({
	
         // large
         "(min-width: 558px)": function() {
            ScrollTrigger.create({
               trigger: module,
               start: "top+=100px bottom",
               toggleClass: "o-fadeInUp__animated",
               once: true,
               // markers: true
      
            })
         },
       
         // small
         "(max-width: 557px)": function() {
            ScrollTrigger.create({
               trigger: module,
               start: "top+=20px bottom",
               toggleClass: "o-fadeInUp__animated",
               once: true,
               // markers: true
      
            })
         }
          
      }); 
   });

   // accordeon animation home

   const allProjects = [...document.querySelectorAll(`.js-parallax-parent`)]
   allProjects.map((project, i) => {
   const projectImage = project.querySelector(`.js-parallax-children`)
   const heightDiff = projectImage.offsetHeight - project.offsetHeight

   gsap.set(projectImage, { y: -heightDiff })

   gsap.fromTo(projectImage,
      { y: -heightDiff },
      {
         scrollTrigger: {
            trigger: project,
            scrub: true,
            id: `js-parallax-project`,
            start: 'top bottom',
            end: 'bottom top',
            fastScrollEnd: true,
            },
         y: 0,
         ease: 'none'
      })
   })

   // section animation (home)

   const activeClass = 'is-active';
   const menuItem = 'js-menu-item';
   const listItem = 'js-list-item';

   // let parentTarget = document.querySelector('.c-navigation__grid');
   // let menuTarget = document.querySelector('.c-navigation__sticky-target');

   // let parentHeight = parentTarget.offsetHeight;
   // let menuHeight = menuTarget.offsetHeight;

   // console.log(parentHeight);
   // console.log(menuHeight);

   const items = [...document.querySelectorAll("." + listItem)];
   items.map((item, index) => {

      ScrollTrigger.matchMedia({
	
         // large
         "(min-width: 558px)": function() {
            ScrollTrigger.create({
               id: 'item-' + (index+1),
               trigger: item,
               // markers: true,
               start: "top+=0px top+=30%",
               end: () => {
                  return "+=" + item.offsetHeight
               },
               onEnter: ({progress, direction, isActive}) => {
                  const currentListItemActive = document.querySelector('.' + listItem + '.' + activeClass);
                  if(currentListItemActive) currentListItemActive.classList.remove(activeClass);
                  
                  const currentMenuItemActive = document.querySelector('.' + menuItem + '.' + activeClass);
                  if(currentMenuItemActive) currentMenuItemActive.classList.remove(activeClass);
                  
                  item.classList.add(activeClass);
                  
                  const currentIndex = index+1;
                  const currentMenuItem = document.querySelector('.js-index-' + currentIndex);
                  if(currentMenuItem) currentMenuItem.classList.add(activeClass);
                  
               },
               onEnterBack: ({progress, direction, isActive}) => {
                  const currentListItemActive = document.querySelector('.' + listItem + '.' + activeClass);
                  if(currentListItemActive) currentListItemActive.classList.remove(activeClass);
                  
                  const currentMenuItemActive = document.querySelector('.' + menuItem + '.' + activeClass);
                  if(currentMenuItemActive) currentMenuItemActive.classList.remove(activeClass);
                  
                  item.classList.add(activeClass);
                  
                  const currentIndex = index+1;
                  const currentMenuItem = document.querySelector('.js-index-' + currentIndex);
                  if(currentMenuItem) currentMenuItem.classList.add(activeClass);
      
               },
               onLeave: (ST) => {
                  
               },
               onLeaveBack: (ST) => {
                  
               }
            }); 


            // ScrollTrigger.create({
            //    pin: true,
            //    ease: 'none',
            //    pinSpacing: false,
            //    start: "top+=0px top",
            //    end: "+=" + parentHeight + "-" + menuHeight + "bottom-=35%",
            //    // end: "bottom bottom",
            //    trigger: '.c-navigation__sticky-target',
            //    markers: true,
            // });
         

        
         },
       
         // small
         "(max-width: 557px)": function() {
            ScrollTrigger.create({
               id: 'item-' + (index+1),
               trigger: item,
               // markers: true,
               start: "top+=0px center",
               end: () => {
                  return "+=" + item.offsetHeight
               },
               onEnter: ({progress, direction, isActive}) => {
                  const currentListItemActive = document.querySelector('.' + listItem + '.' + activeClass);
                  if(currentListItemActive) currentListItemActive.classList.remove(activeClass);
                  
                  const currentMenuItemActive = document.querySelector('.' + menuItem + '.' + activeClass);
                  if(currentMenuItemActive) currentMenuItemActive.classList.remove(activeClass);
                  
                  item.classList.add(activeClass);
                  
                  const currentIndex = index+1;
                  const currentMenuItem = document.querySelector('.js-index-' + currentIndex);
                  if(currentMenuItem) currentMenuItem.classList.add(activeClass);
                  
               },
               onEnterBack: ({progress, direction, isActive}) => {
                  const currentListItemActive = document.querySelector('.' + listItem + '.' + activeClass);
                  if(currentListItemActive) currentListItemActive.classList.remove(activeClass);
                  
                  const currentMenuItemActive = document.querySelector('.' + menuItem + '.' + activeClass);
                  if(currentMenuItemActive) currentMenuItemActive.classList.remove(activeClass);
                  
                  item.classList.add(activeClass);
                  
                  const currentIndex = index+1;
                  const currentMenuItem = document.querySelector('.js-index-' + currentIndex);
                  if(currentMenuItem) currentMenuItem.classList.add(activeClass);
      
               },
               onLeave: (ST) => {
                  
               },
               onLeaveBack: (ST) => {
                  
               }
            });  
         }
          
      }); 
         
   });

   // section scroll align center


   const activeClass2 = 'is-active';
   const menuItem2 = 'js-menu';
   const listItem2 = 'js-list';

   const items2 = [...document.querySelectorAll("." + listItem2)];
   items2.map((item, index) => {

      ScrollTrigger.create({
         id: 'item-' + (index+1),
         trigger: item,
         // markers: true,
         start: "top+=0px center",
         end: () => {
            return "+=" + item.offsetHeight
         },
         onEnter: ({progress, direction, isActive}) => {
            const currentListItemActive = document.querySelector('.' + listItem2 + '.' + activeClass2);
            if(currentListItemActive) currentListItemActive.classList.remove(activeClass2);
            
            const currentMenuItemActive = document.querySelector('.' + menuItem2 + '.' + activeClass2);
            if(currentMenuItemActive) currentMenuItemActive.classList.remove(activeClass2);
            
            item.classList.add(activeClass2);
            
            const currentIndex = index+1;
            const currentMenuItem = document.querySelector('.js-index-' + currentIndex);
            if(currentMenuItem) currentMenuItem.classList.add(activeClass2);
            
         },
         onEnterBack: ({progress, direction, isActive}) => {
            const currentListItemActive = document.querySelector('.' + listItem2 + '.' + activeClass2);
            if(currentListItemActive) currentListItemActive.classList.remove(activeClass2);
            
            const currentMenuItemActive = document.querySelector('.' + menuItem2 + '.' + activeClass2);
            if(currentMenuItemActive) currentMenuItemActive.classList.remove(activeClass2);
            
            item.classList.add(activeClass2);
            
            const currentIndex = index+1;
            const currentMenuItem = document.querySelector('.js-index-' + currentIndex);
            if(currentMenuItem) currentMenuItem.classList.add(activeClass2);

         },
         onLeave: (ST) => {
            
         },
         onLeaveBack: (ST) => {
            
         }
      });           
   });

   // cursor

   const cursorEl = document.querySelector('.js-cursor');

      const cur = document.querySelector('.c-cursor');
      const follow = document.querySelector('.c-cursor__hover');


      const addEventListeners = () => {
            document.addEventListener("mousemove", onMouseMove);
            // document.addEventListener("mousedown", onMouseDown);
            // document.addEventListener("mouseup", onMouseUp);
            document.addEventListener("mouseenter", onMouseEnter);
            document.addEventListener("mouseleave", onMouseLeave);
            handleLinkHoverEvents();
            handleLinkHoverEvents2();
      };

      const onMouseMove = (e) => {
            // gsap
            gsap.to(cur, {x:e.clientX, y:e.clientY, duration: 0.1});
            gsap.to(follow, {x:e.clientX, y:e.clientY, duration: 0.3});
      };

      // const onMouseDown = () => {
      //     cursorEl.classList.add(isClickedClass);
      // };
   
      // const onMouseUp = () => {
      //     cursorEl.classList.remove(isClickedClass);
      // };

      const onMouseEnter = () => {
            gsap.to(cur, {opacity: 1});
            // gsap.to(follow, {opacity: 1});
      };
   
      const onMouseLeave = () => {
            gsap.to(cur, {opacity: 0});
            // gsap.to(follow, {opacity: 0});
      };

      const handleLinkHoverEvents = () => {
            document.querySelectorAll('.js-link').forEach((el) => {
               el.addEventListener("mouseover", () => {
                  gsap.to(cur, {opacity: 0});
                  gsap.to(follow, {opacity: 1});
               } );
               el.addEventListener("mouseout", () => {
                  gsap.to(cur, {opacity: 1});
                  gsap.to(follow, {opacity: 0});
               } );
            });

            document.querySelectorAll('.js-link-media').forEach((el) => {
               el.addEventListener("mouseover", () => {
                  gsap.to(cur, {opacity: 0});
               } );
               el.addEventListener("mouseout", () => {
                  gsap.to(cur, {opacity: 1});
               } );
            });
      };

      const handleLinkHoverEvents2 = () => {
            document.querySelectorAll('.js-link-hover').forEach((el) => {
               el.addEventListener("mouseover", () => {
                  gsap.to(cur, {width: 20, height: 20});
               } );
               el.addEventListener("mouseout", () => {
                  gsap.to(cur, {width: 10, height: 10});
               } );
            });
      };

   // https://stackoverflow.com/a/4819886
   function isTouchDevice() {
   return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
   }

   const isTouch = isTouchDevice();

   if (!isTouch){

      
      // const isClickedClass = 'is-clicked';
      // const hasCustomCursorClass = 'has-custom-cursor';

      // gsap reset center
      gsap.set('.c-cursor', {xPercent:-50, yPercent:-50});
      gsap.set('.c-cursor__hover', {xPercent:-50, yPercent:-50});

   

      addEventListeners();
      // document.body.classList.add(hasCustomCursorClass);

   }

// document ready
$(function() {

   // menu mobile
   var $openMenu = $("#js-open-menu"),
   $closeMenu = $("#js-close-menu"),
   $menuWrap = $(".b-menu-mobile");

   $openMenu.on("click", function(){
      $menuWrap.addClass("is-show");
      $(".b-menu-mobile__inner").addClass("is-active");
      $("html").addClass("no-scroll");
   });

   $closeMenu.on("click", function(){
      $menuWrap.removeClass("is-show is-active");
      $(".b-menu-mobile__inner").removeClass("is-active");
      $("html").removeClass("no-scroll");
   });

   // header

   var last_scroll_top = 0;
	var top_display = 100;

	function i_scroll() {

      last_scroll_top = 0;
      top_display = 100;

      $(window).off('scroll')
      $(window).on('scroll', function(){

         var scroll_top = $(this).scrollTop();

         if ((scroll_top > last_scroll_top) && (scroll_top > top_display)) {
            //downscroll code
            $('.js-header').addClass('has-transform');
            $('.c-lang__list').removeClass('c-lang__list--show');
            $('.c-lang__icon').removeClass('c-lang__icon--rotate');
         } else {
            $('.js-header').removeClass('has-transform');
         }

         last_scroll_top = scroll_top;
      });

   }

   i_scroll();

   // change color header on scroll

   let hero = $(".c-hero__wrapper").height();
   // var header = $(".b-header");
   // let height = screen.height;
  
   $(window).scroll(function() {    

      var scroll = $(window).scrollTop();
      if (scroll >= hero ) {
         $('.b-body__home').addClass("b-body__modifier");
      } else {
         $('.b-body__home').removeClass('b-body__modifier');
      }
   });
   
   // swiper slide

   var swiper = new Swiper(".mySwiper", {
      spaceBetween: 15,
      navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      },
   });

   var swiper2 = new Swiper("#mySwiper2", {
      spaceBetween: 15,
      navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      },
   });

   swiper2.on('slideChange', function () {

      var videos = document.querySelectorAll('.js-swiper-target');

      console.log(videos);
      Array.prototype.forEach.call(videos, function (video) {
         if (video.tagName.toLowerCase() === 'video') {
            video.pause();
         } else {
            var src = video.src;
            video.src = src;
         }
      });
      
   });
    


   // languages menu
   $('.c-lang__button').on('click', function(){
      $('.c-lang__list').toggleClass('c-lang__list--show');
      $('.c-lang__icon').toggleClass('c-lang__icon--rotate');
   });
   
   $window =  $(window);

   $window.on('scroll', function () {
        if ( $('.js-navigation__menu').length != 0 ) {
           scrollsFixed(); 
        }
    });

    $window.trigger('scroll');

    function scrollsFixed() {
        $( ".js-navigation__menu" ).each(function(i) {
            var $windowTopPos = $window.scrollTop();
            var $sContainer = $(this).closest('.js-navigation__parent');
            var $sFixed = $(this);
                
            if ($sContainer.offset().top < $windowTopPos){
                if ($sContainer.height() <= $windowTopPos - $sContainer.offset().top + $sFixed.height()){
                    $sFixed.css('position','relative');
                    $sFixed.css('top',$sContainer.height() - $sFixed.height());
                } else {
                    $sFixed.css('position','fixed');
                    $sFixed.css('top', 0);
                }
            } else {
                $sFixed.css('position','').css('top','');
            }
    
        });
    }

});