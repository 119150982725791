(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name Proyecto
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('Home', Home);

    Home.$inject = ["ProjectService","$timeout","$location",'$anchorScroll'];

    function Home(ProjectService,$timeout,$location,$anchorScroll)
    {
        var vm = this;

        vm.projectsHome = function(){
            ProjectService.getHome().then(function(data){ 
                console.log(vm.projects);
                vm.projects = data.projects;
            })
        }
    }


})();