(function(){
    'use strict';

/**
* @memberof app
* @ngdoc controller
* @name Proyecto
* @description
* This is main controller
**/ 

    angular
    .module('app')
    .controller('Proyecto', Proyecto);

    Proyecto.$inject = ["ProjectService","$timeout","$location",'$anchorScroll'];

    function Proyecto(ProjectService,$timeout,$location,$anchorScroll)
    {
        var vm = this;
        vm.projects = "";
        vm.currentCat = "";
        vm.offset=0;
        vm.loading = true;
        vm.limit = 8;
        vm.loadingInit = true;
    	vm.showProjects = function(init){
            if(typeof(init)!='undefined'){
                vm.loadingInit = true;
            }

            vm.loading = true;
     		ProjectService.getProjects(vm.offset,vm.limit).then(function(data){ 

                vm.loading=false;
                vm.loadingInit = false;

                if(vm.offset<1){
                    vm.projects = data.projects;
                    console.log("inicio proyectos", vm.projects); 
                    vm.limit = 4; 
                    vm.offset +=2; 
                }else{
                    vm.offset++;
                    vm.projects = vm.projects.concat(data.projects);
                    console.log("siguientes",vm.projects);
                }
                
                if(vm.offset==data.maxPag){
                    vm.hideButton= true;
                }
                
                $timeout(function(){
                    addEventListeners();
                })
        	})
 		}
        vm.showProjects(true);
    }


})();

